import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export function nestedArray(data, emptyArr, isPath = false, name_id = 'parent_id', root = null) {
  const current = data.filter(item => item[name_id] === (root ? root.id : null))
  if (current.length === 0) {
    const elem = data.filter(item => item.id === root.id)[0]
    elem.is_trash = true
  } else if (root) {
    // eslint-disable-next-line no-param-reassign
    root.is_trash = false
  }
  if (current.length > 0) {
    current.forEach(item => {
      const split = item.relation.split('/')
      const total = split.length - 1
      let trace = ''
      let strRelation = ''
      for (let i = 0; i < total; i += 1) {
        trace += ' - '
        strRelation += `${split[i]}/`
      }
      if (isPath) {
        // eslint-disable-next-line no-param-reassign
        item.name = `${trace + item.name} ${total > 0 ? ` - (${strRelation})` : ''}`
      } else {
        // eslint-disable-next-line no-param-reassign
        item.name = `${trace + item.name}`
      }
      emptyArr.push(item)
      nestedArray(data, emptyArr, isPath, name_id, item)
    })
  }
}

export function filterDownRelation(data, root, name_id = 'parent_id', deletArr) {
  const current = data.filter(item => item[name_id] === (root ? root.id : null))
  if (current.length > 0) {
    current.forEach(item => {
      deletArr.push(item.id)
      filterDownRelation(data, item, name_id, deletArr)
    })
  }
}

export function toastMessage(type, titleMsg, msg) {
  Vue.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: titleMsg,
      icon: type ? 'CheckCircleIcon' : 'XIcon',
      variant: type ? 'success' : 'danger',
      text: msg,
    },
  })
}
