<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <b-overlay
      variant="transparent"
      :show="load"
      rounded="sm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Adicionar novo usuário
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Nome"
              rules="required"
            >
              <b-form-group
                label="Nome"
                label-for="name"
              >
                <v-select
                  id="name"
                  v-model="userData.name"
                  taggable
                  placeholder="Selecione"
                  @option:selected="selectUser"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- registration -->
            <validation-provider
              #default="validationContext"
              name="Matrícula"
              rules="required"
            >
              <b-form-group
                label="Matrícula"
                label-for="r_code"
              >
                <b-form-input
                  id="r_code"
                  v-model="userData.r_code"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="0000"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Office -->
            <validation-provider
              #default="validationContext"
              name="Cargo"
              rules="required"
            >
              <b-form-group
                label="Cargo"
                label-for="office"
              >
                <b-form-input
                  id="office"
                  v-model="userData.office"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: analista RH"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Phone -->
            <validation-provider
              #default="validationContext"
              name="Telefone"
              rules="required"
            >
              <b-form-group
                label="Telefone"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="userData.phone"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  :state="getValidationState(validationContext)"
                  placeholder="(00) 00000-0000"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  placeholder="joao@cosama.am.gov.com.br"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Sector -->
            <validation-provider
              #default="validationContext"
              name="Setor"
              rules="required"
            >
              <b-form-group
                label="Setor"
                label-for="sector"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.sector"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sector"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="sector"
                  placeholder="Selecione"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- head office -->
            <validation-provider
              #default="validationContext"
              name="Sede de Operação"
              rules="required"
            >
              <b-form-group
                label="Sede de Operação"
                label-for="head_office"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.head_office"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="head_office"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="head_office"
                  placeholder="Selecione"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- ROLE -->
            <validation-provider
              #default="validationContext"
              name="Perfil"
              rules="required"
            >
              <b-form-group
                label="Perfil"
                label-for="role"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="userData.roles"
                  :options="arr_role"
                  :clearable="false"
                  input-id="role"
                  placeholder="Selecione"
                  label="name"
                  @open="getRoles"
                  @option:selected="selectRole"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Password -->
            <validation-provider
              #default="validationContext"
              name="Senha"
              rules="required"
            >
              <b-form-group
                label="Senha"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  :state="getValidationState(validationContext)"
                  trim
                  type="password"
                  placeholder="******"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Cadastrar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hideSide"
              >
                Cancelar
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-overlay>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, numeric, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { mask } from 'vue-the-mask'
import constants from '@/common/constants'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import axios from '@axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    mask,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { sector } = constants
    const { head_office } = constants
    const usersArr = ref([])
    // eslint-disable-next-line camelcase
    const arr_role = ref([])
    const load = ref(false)

    const toast = useToast()
    const blankUserData = {
      r_code: '',
      name: '',
      office: '',
      phone: '',
      email: '',
      birthday: '',
      sector: null,
      head_office: null,
      password: '',
      roles: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      load.value = true
      store.dispatch('user/addUser', userData.value)
        .then(e => {
          if (e.data.success) {

            load.value = false

            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)

            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Cadastro de usuário',
                icon: res.data.status ? 'CheckIcon' : 'XIcon',
                variant: res.data.status ? 'success' : 'danger',
                text: res.data.message,
              },
            })

            /*axios({
              method: 'post',
              url: '/general/users/update/especific',
              headers: {
                'Content-Type': 'application/json',
              },
              data: { list: [userData.value.r_code] },
            }).then(res => {
              load.value = false

              emit('refetch-data')
              emit('update:is-add-new-user-sidebar-active', false)

              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Cadastro de usuário',
                  icon: res.data.status ? 'CheckIcon' : 'XIcon',
                  variant: res.data.status ? 'success' : 'danger',
                  text: res.data.message,
                },
              })
            })*/
          }
        })
    }

    const getUsers = () => {
      store.dispatch('user/getUsersErp', userData.value).then(e => {
        usersArr.value = e.data.return
      })
    }

    const getRoles = () => {
      axios.get('/roles').then(res => {
        arr_role.value = res.data.data
      })
    }

    const hideSide = () => {
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const selectUser = e => {
      userData.value.r_code = e.RA_MAT
      userData.value.password = e.RA_CIC
    }
    const selectRole = e => {
      userData.value.roles = [e.name]
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      sector,
      head_office,
      arr_role,
      refFormObserver,
      getValidationState,
      resetForm,
      getUsers,
      usersArr,
      selectUser,
      getRoles,
      selectRole,
      load,
      hideSide,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
